import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { useSelector } from "react-redux";
import { Minus, Plus } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "8px",
    border: "1px solid #A0A9B6",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "35px",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0.4),
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      height: "18px",
      width: "35px",
    },
    "& .MuiFormControl-marginDense": {
      margin: 0,
      backgroundColor: "white",
    },
  },
  text: {
    "& input": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
      background: "white",
      padding: "8px 0 8px 0",
      borderLeft: "1px solid #A0A9B6",
      borderRight: "1px solid #A0A9B6",
      width: "55px",
      [theme.breakpoints.down("md")]: {
        border: "none",
      },
    },
  },
  leftButton: {
    marginLeft: theme.spacing(0.2),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  rightButton: {
    marginRight: theme.spacing(0.2),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

function AddOrRemove({ handleValueChanged, value, min, max, disabled }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const { sendingCart } = useSelector(state => state.cart);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const isValidInputValue = newInputValue =>
    !Number.isNaN(newInputValue) &&
    newInputValue >= min &&
    newInputValue <= max;

  const updateCount = count => {
    if (!isValidInputValue(count)) {
      return;
    }
    // pass to handler
    handleValueChanged(count);
  };

  const disableMinus = value <= min || disabled === true || sendingCart;
  const disablePlus = value >= max || disabled === true || sendingCart;

  return (
    <div className={classes.root}>
      <IconButton
        id="minus-btn"
        className={classes.leftButton}
        size="small"
        onClick={() => updateCount(value - 1)}
        disabled={disableMinus}
      >
        <Minus disabled={disableMinus} />
      </IconButton>
      <div className={classes.textContainer}>
        <InputBase
          className={classes.text}
          error={!isValidInputValue(inputValue)}
          autoFocus={false}
          margin="dense"
          size="small"
          variant="outlined"
          value={inputValue}
          onChange={event => {
            setInputValue(event.target.value);
          }}
          inputProps={{
            maxLength: 3,
            disableUnderline: true,
            style: {},
          }}
          onBlur={event => {
            isValidInputValue(inputValue)
              ? // eslint-disable-next-line
                updateCount(parseInt(event.target.value))
              : setInputValue(value);
          }}
          id="product-amount-input"
        />
      </div>
      <IconButton
        id="plus-btn"
        className={classes.rightButton}
        size="small"
        onClick={() => updateCount(value + 1)}
        disabled={disablePlus}
      >
        <Plus disabled={disablePlus} />
      </IconButton>
    </div>
  );
}

AddOrRemove.propTypes = {
  handleValueChanged: PropTypes.func,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

AddOrRemove.defaultProps = {
  handleValueChanged: () => {}, // eslint-disable-line
  disabled: false,
};

// extended main view with translate hoc
export default AddOrRemove;
