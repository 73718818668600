/* eslint-disable consistent-return */
import moment from "moment";
// eslint-disable-next-line
import { RestService } from "../../../components/generic";
import ensureTrailingSlash from "../../../utils/url/url";
import Delay from "../../../utils/delay/delay";

export const Invoice = Object.freeze({
  FETCH_INVOICES_STARTED: "FETCH_INVOICES_STARTED",
  FETCH_INVOICES_FINISHED: "FETCH_INVOICES_FINISHED",
  FETCH_INVOICES_ERROR: "FETCH_INVOICES_ERROR",
  FETCH_INVOICE_STARTED: "FETCH_INVOICE_STARTED",
  FETCH_INVOICE_FINISHED: "FETCH_INVOICE_FINISHED",
  FETCH_INVOICE_ERROR: "FETCH_INVOICE_ERROR",
  FETCH_FAVORITES_STARTED: "FETCH_INVOICE_FAVORITES_STARTED",
  FETCH_FAVORITES_FINISHED: "FETCH_INVOICE_FAVORITES_FINISHED",
  FETCH_FAVORITES_ERROR: "FETCH_INVOICE_FAVORITES_ERROR",
  SEND_FAVORITES_STARTED: "SEND_INVOICE_FAVORITES_STARTED",
  SEND_FAVORITES_FINISHED: "SEND_INVOICE_FAVORITES_FINISHED",
  SEND_FAVORITES_ERROR: "SEND_INVOICE_FAVORITES_ERROR",
  UPDATE_FAVORITES: "UPDATE_INVOICE_FAVORITES",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ECOM_SERVICE);
const invoiceApi = ensureTrailingSlash(process.env.REACT_APP_INVOICE_API);
const ecomApi = ensureTrailingSlash(process.env.REACT_APP_ECOM_API);
const ISO_8601_FORMAT = "YYYY-MM-DD";
const delay = new Delay();

export const fetchInvoices = customerIds => async (dispatch, getState) => {
  const startIndex = 0;
  const pageSize = 1000;
  // Same attributes than in order tab, so startDate, endDate and query are stored already in order reducer
  const { startDate, endDate } = getState().order;
  const startDateStr = moment(startDate).format(ISO_8601_FORMAT);
  const endDateStr = moment(endDate).format(ISO_8601_FORMAT);

  const path = `${baseUrl}${invoiceApi}?startIndex=${startIndex}&pageSize=${pageSize}&startDate=${startDateStr}&endDate=${endDateStr}&customerIds=${customerIds.join(
    ","
  )}`;

  try {
    // start
    dispatch({ type: Invoice.FETCH_INVOICES_STARTED });

    const invoices = await RestService.get(path);

    // update invoices
    dispatch({
      type: Invoice.FETCH_INVOICES_FINISHED,
      payload: invoices,
    });

    return invoices;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Invoice.FETCH_INVOICES_ERROR, payload: error });
  }
};

export const fetchInvoicesByIds = invoiceIdArray => async dispatch => {
  try {
    // sanity
    let invoices = [];
    if (invoiceIdArray.length > 0) {
      const path = `${baseUrl}${invoiceApi}invoice-id/?invoiceIds=${invoiceIdArray.join(
        ","
      )}`;

      // start
      dispatch({ type: Invoice.FETCH_INVOICES_STARTED });

      invoices = await RestService.get(path);
      // sort by time
      invoices.sort(
        (a, b) => moment(b.invoiceDate).unix() - moment(a.invoiceDate).unix()
      );
    }

    // update invoices
    dispatch({
      type: Invoice.FETCH_INVOICES_FINISHED,
      payload: invoices,
    });

    return invoices;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Invoice.FETCH_INVOICES_ERROR, payload: error });
  }
};

export const fetchInvoicePDF = (customerId, invoiceId) => async dispatch => {
  const path = `${baseUrl}${invoiceApi}pdf/${customerId}/${invoiceId}`;

  try {
    // start
    dispatch({ type: Invoice.FETCH_INVOICE_STARTED, payload: invoiceId });

    const invoice = await RestService.get(path);

    // update invoice
    dispatch({
      type: Invoice.FETCH_INVOICE_FINISHED,
      payload: invoice,
    });

    return invoice;
  } catch (error) {
    // TODO: error handling
    dispatch({ type: Invoice.FETCH_INVOICE_ERROR, payload: error });
  }
};

export const fetchFavoriteInvoices = userId => async dispatch => {
  try {
    const path = `${baseUrl}${ecomApi}favorite/invoice/${userId}`;

    // start
    dispatch({ type: Invoice.FETCH_FAVORITES_STARTED });

    const favoriteInvoiceIds = await RestService.get(path);

    // finished
    dispatch({
      type: Invoice.FETCH_FAVORITES_FINISHED,
      payload: favoriteInvoiceIds,
    });

    return favoriteInvoiceIds;
  } catch (error) {
    dispatch({ type: Invoice.FETCH_FAVORITES_ERROR, payload: error });
  }
};

const doSendFavoriteInvoiceId = async (
  dispatch,
  userId,
  invoiceNumber,
  customerId,
  marked
) => {
  try {
    const path = `${baseUrl}${ecomApi}favorite/invoice/${userId}`;

    // start
    dispatch({ type: Invoice.SEND_FAVORITES_STARTED });

    const result = await RestService.post(path, {
      invoiceNumber,
      customerId,
      marked,
    });

    // finished
    dispatch({ type: Invoice.SEND_FAVORITES_FINISHED });

    return result;
  } catch (error) {
    dispatch({ type: Invoice.SEND_FAVORITES_ERROR, payload: error });
  }
};

export const setFavoriteInvoice =
  (userId, invoice, value) => (dispatch, getState) => {
    const favoriteInvoiceIds = getState().invoice.favoriteInvoiceIds.slice(0);
    const { invoiceNumber, customerId } = invoice;

    // adding or removing
    if (value === true) {
      favoriteInvoiceIds.push(invoiceNumber);
    } else {
      const index = favoriteInvoiceIds.indexOf(invoiceNumber);
      favoriteInvoiceIds.splice(index, 1);
    }

    // update to local state
    dispatch({
      type: Invoice.UPDATE_FAVORITES,
      payload: {
        ids: favoriteInvoiceIds,
        analytics: {
          add: value,
          invoice,
        },
      },
    });

    // delay send (to handle heavy mouse clickers)
    delay.run(() => {
      doSendFavoriteInvoiceId(
        dispatch,
        userId,
        invoiceNumber,
        customerId,
        value
      );
    }, 100);
  };

const INIT_STATE = {
  invoices: [],
  invoice: {},
  favoriteInvoiceIds: [],
  fetchingInvoices: false,
  invoicesFetchError: null,
  fetchingFavorites: false,
  favoritesFetchError: null,
  sendingFavorites: false,
  favoritesSendError: false,
  invoiceFetchError: null,
  invoiceId: null,
};
// eslint-disable-next-line
export const invoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Invoice.FETCH_INVOICES_STARTED:
      return { ...state, fetchingInvoices: true, invoicesFetchError: null };
    case Invoice.FETCH_INVOICES_FINISHED: {
      return { ...state, fetchingInvoices: false, invoices: action.payload };
    }
    case Invoice.FETCH_INVOICES_ERROR:
      return {
        ...state,
        fetchingInvoices: false,
        invoicesFetchError: action.payload,
      };
    case Invoice.FETCH_INVOICE_STARTED:
      return {
        ...state,
        fetchingInvoice: true,
        invoiceId: action.payload,
        invoiceFetchError: null,
      };
    case Invoice.FETCH_INVOICE_FINISHED: {
      return { ...state, fetchingInvoice: false, invoice: action.payload };
    }
    case Invoice.FETCH_INVOICE_ERROR:
      return {
        ...state,
        fetchingInvoice: false,
        invoiceFetchError: action.payload,
      };
    case Invoice.FETCH_FAVORITES_STARTED: {
      return { ...state, fetchingFavorites: true, favoritesFetchError: null };
    }
    case Invoice.FETCH_FAVORITES_FINISHED: {
      return {
        ...state,
        fetchingFavorites: false,
        favoriteInvoiceIds: action.payload,
      };
    }
    case Invoice.FETCH_FAVORITES_ERROR: {
      return {
        ...state,
        fetchingFavorites: false,
        favoritesFetchError: action.payload,
      };
    }
    case Invoice.UPDATE_FAVORITES: {
      return { ...state, favoriteInvoiceIds: action.payload.ids };
    }
    case Invoice.SEND_FAVORITES_STARTED:
      return { ...state, sendingFavorites: true, favoritesSendError: null };
    case Invoice.SEND_FAVORITES_FINISHED:
      return { ...state, sendingFavorites: false };
    case Invoice.SEND_FAVORITES_ERROR:
      return {
        ...state,
        sendingFavorites: false,
        favoritesSendError: action.payload,
      };
    case Invoice.CLEAR_ALL_ERRORS:
      return {
        ...state,
        favoritesFetchError: null,
        invoiceFetchError: null,
        favoritesSendError: null,
        invoicesFetchError: null,
      };
    default:
      return state;
  }
};
